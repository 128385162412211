import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { graphql } from "gatsby";
import get from "lodash/get";
import { resolveComponents } from "../helpers/componentResolver";
import Layout from "../components/layout";
import Seo from "../components/seo";
import CookieBar from "../components/cookiebar";
import BackToTopButton from "./../components/Generic/backToTop";
import LoadingAnimation from "./../components/Generic/loadingAnimation";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import PageBanner from "../components/pageBanner";

function DynamicPage(props) {
  const page = props.page ? props.page : get(props, "data.contentfulPage");
  page.seo.url = page.slug;

  const breakpoints = useBreakpoint();

  return (
    <Layout language={page.node_locale} brandTheme={page.brandTheme}>
      <Seo meta={page.seo} />
      <PageBanner data={page.pageBanner} brandTheme={page.brandTheme} />
      <div className="sections">
        {resolveComponents(page.body).map((element) => {
          return element;
        })}
      </div>
      <CookieBar language={page.node_locale} />
      {!breakpoints.md && <BackToTopButton language={page.node_locale} />}
      <LoadingAnimation />
    </Layout>
  );
}

export default DynamicPage;

export const pageQuery = graphql`
  query pageBySlug($slug: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          language
          ns
          data
        }
      }
    }
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $language }) {
      id
      slug
      title
      node_locale
      brandTheme
      seo {
        ... on ContentfulSeo {
          ...SeoFragment
        }
      }
      pageBanner {
        ... on ContentfulPageBanner {
          ...PageBannerFragment
        }
      }
      body {
        __typename
        ... on ContentfulList {
          ...ListFragment
        }
        ... on ContentfulHero {
          ...HeroFragment
        }
        ... on ContentfulSlider {
          ...SliderFragment
        }
        ... on ContentfulVideo {
          ...VideoFragment
        }
        ... on ContentfulGenericComponent {
          type
        }
        ... on ContentfulImageBoxes {
          ...ImageBoxesFragment
        }
        ... on ContentfulQuote {
          ...QuoteFragment
        }
        ... on ContentfulImageWithText {
          ...ImageWithTextFragment
        }
        ... on ContentfulAlternatingTextWithImage {
          ...AlternatingTextWithImage
        }
        ... on ContentfulFloatingImageWithVideoAndText {
          ...FloatingImageWithVideoAndText
        }
      }
    }
  }
`;
